import { baseAxios } from "../index";

export async function getAllPengajuan(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&idWilayah=${data.idWilayah}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENGAJUAN", data: response.data });
    // console.log(response, "PENGAJUANNNNNNNNNNNNNNNNNNNn");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPencairan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&idWilayah=${data.idWilayah}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalTargetUser(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      `webDashboard/getTargetUserLogin?idWilayah=${data.idWilayah}`,
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });

    return response;
  } catch (err) {
    return err.response;
  }
}

// export async function revisiPengajuan(data) {
//   try {
//     const response = await baseAxios.post(
//       "webPengajuan/revisiPengajuanDariAtasan",
//       data,
//       {
//         headers: {
//           token: localStorage.getItem("token"),
//         },
//       }
//     );
//     return response;
//   } catch (err) {
//     return err.response;
//   }
// }

// export async function pengajuanBPD(data) {
//   try {
//     const response = await baseAxios.post("webApiBpd/pengajuan", data, {
//       headers: {
//         token: localStorage.getItem("token"),
//       },
//     });
//     // console.log(response, "DARI AXIOSSSSSS");
//     return response;
//   } catch (err) {
//     return err.response;
//   }
// }

export async function updateStatusPengajuan(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/updateStatusPengajuanManual",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    // console.log(response, "DARI AXIOSSSSSS");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function jsonPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(`mobileListJson/allJson`, {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "JSON_PENGAJUAN", data: response.data });
    // console.log(response.data, "JSONNNNNNN");
    return response.status;
  } catch (err) {
    // console.log(err, "JSON GAGAL");
    return err.response;
  }
}

export async function showUrlDokumen(dispatch, data, history) {
  try {
    const response = await baseAxios.post("webPengajuan/showUrlDokumen", data, {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({
      type: "SHOW_URL_DOKUMEN",
      data: response.data.url,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
